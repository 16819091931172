<script>
	import { fade } from 'svelte/transition';
	import { IsElectron, Version } from '$lib/store.js';
	import { getContext } from 'svelte';
	let sshow = getContext('show');
	export let fullScreen = true;
	export let loaded = undefined;
	export let loadingText = undefined;
	export let expectedHeight = undefined;
</script>

<!-- <div
	class="absolute centreInContext transform translate-y-20 text-xs font-semibold text-center z-50"
>
	{loadingText || 'Take a deep breath, plan your next move.'}
</div> -->
{#if (!sshow || $sshow) && !loaded}
	<div
		transition:fade|local={{ delay: 0, duration: 1000 }}
		style="min-height: {!loaded ? `${expectedHeight}px` : '0px;display:none'};{fullScreen
			? ''
			: `position:absolute;-webkit-app-region: drag;${
					$IsElectron ? 'cursor:move;' : ''
			  }height:100%;width:100%;z-index:2;`}"
		class="{fullScreen ? 'orbBackground' : 'orbNoBackground'} pointer-events-none"
	>
		{#if (!sshow || $sshow) && fullScreen && !loaded && $Version?.app !== 'omnipilot'}
			<div
				class="absolute centreInContext mt-20 text-sm font-medium text-center"
				style="z-index:10001;opacity:{(!sshow || $sshow) && fullScreen && !loaded ? 1 : 0};"
			>
				{loadingText || 'Take a deep breath, plan your next move.'}
			</div>
		{/if}
		<div transition:fade|local={{ duration: 200 }} class="wrap pointer-events-none">
			{#each Array(30).fill() as particle}
				<div class="c" />
			{/each}
		</div>
	</div>

	<style>
		.orbBackground {
			position: fixed;
			z-index: 10001;
			top: 0;
			left: 0;
			height: 100vh;
			width: 100vw;
			background: #060f1f;
		}
		.orbNoBackground {
			position: fixed;
			z-index: 10001;
			top: 0;
			left: 0;
			height: 100vh;
			width: 100vw;
		}
		.wrap {
			position: relative;
			top: 50%;
			left: 50%;
			width: 0;
			height: 0;
			z-index: 10000;
			transform-style: preserve-3d;
			perspective: 1000px;
			animation: rotate 6s infinite linear;
		}

		@keyframes rotate {
			100% {
				transform: rotateY(360deg) rotateX(360deg);
			}
		}
		.c {
			position: absolute;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			opacity: 0;
		}
		/* animation-play-state: 'paused' / 'running'; */
		.c:nth-child(1) {
			animation: orbit1 6s infinite;
			animation-delay: 0.01s;
			background-color: #00a7ff;
		}

		@keyframes orbit1 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-136deg) rotateY(327deg) translateX(50px) rotateZ(136deg);
			}
			80% {
				transform: rotateZ(-136deg) rotateY(327deg) translateX(50px) rotateZ(136deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-136deg) rotateY(327deg) translateX(150px) rotateZ(136deg);
			}
		}
		.c:nth-child(2) {
			animation: orbit2 6s infinite;
			animation-delay: 0.02s;
			background-color: #00a3ff;
		}

		@keyframes orbit2 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-237deg) rotateY(287deg) translateX(50px) rotateZ(237deg);
			}
			80% {
				transform: rotateZ(-237deg) rotateY(287deg) translateX(50px) rotateZ(237deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-237deg) rotateY(287deg) translateX(150px) rotateZ(237deg);
			}
		}
		.c:nth-child(3) {
			animation: orbit3 6s infinite;
			animation-delay: 0.03s;
			background-color: #00a0ff;
		}

		@keyframes orbit3 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-241deg) rotateY(118deg) translateX(50px) rotateZ(241deg);
			}
			80% {
				transform: rotateZ(-241deg) rotateY(118deg) translateX(50px) rotateZ(241deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-241deg) rotateY(118deg) translateX(150px) rotateZ(241deg);
			}
		}
		.c:nth-child(4) {
			animation: orbit4 6s infinite;
			animation-delay: 0.04s;
			background-color: #009cff;
		}

		@keyframes orbit4 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-76deg) rotateY(305deg) translateX(50px) rotateZ(76deg);
			}
			80% {
				transform: rotateZ(-76deg) rotateY(305deg) translateX(50px) rotateZ(76deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-76deg) rotateY(305deg) translateX(150px) rotateZ(76deg);
			}
		}
		.c:nth-child(5) {
			animation: orbit5 6s infinite;
			animation-delay: 0.05s;
			background-color: #0099ff;
		}

		@keyframes orbit5 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-298deg) rotateY(310deg) translateX(50px) rotateZ(298deg);
			}
			80% {
				transform: rotateZ(-298deg) rotateY(310deg) translateX(50px) rotateZ(298deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-298deg) rotateY(310deg) translateX(150px) rotateZ(298deg);
			}
		}
		.c:nth-child(6) {
			animation: orbit6 6s infinite;
			animation-delay: 0.06s;
			background-color: #0096ff;
		}

		@keyframes orbit6 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-341deg) rotateY(81deg) translateX(50px) rotateZ(341deg);
			}
			80% {
				transform: rotateZ(-341deg) rotateY(81deg) translateX(50px) rotateZ(341deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-341deg) rotateY(81deg) translateX(150px) rotateZ(341deg);
			}
		}
		.c:nth-child(7) {
			animation: orbit7 6s infinite;
			animation-delay: 0.07s;
			background-color: #0092ff;
		}

		@keyframes orbit7 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-88deg) rotateY(238deg) translateX(50px) rotateZ(88deg);
			}
			80% {
				transform: rotateZ(-88deg) rotateY(238deg) translateX(50px) rotateZ(88deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-88deg) rotateY(238deg) translateX(150px) rotateZ(88deg);
			}
		}
		.c:nth-child(8) {
			animation: orbit8 6s infinite;
			animation-delay: 0.08s;
			background-color: #008fff;
		}

		@keyframes orbit8 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-105deg) rotateY(100deg) translateX(50px) rotateZ(105deg);
			}
			80% {
				transform: rotateZ(-105deg) rotateY(100deg) translateX(50px) rotateZ(105deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-105deg) rotateY(100deg) translateX(150px) rotateZ(105deg);
			}
		}
		.c:nth-child(9) {
			animation: orbit9 6s infinite;
			animation-delay: 0.09s;
			background-color: #008bff;
		}

		@keyframes orbit9 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-133deg) rotateY(85deg) translateX(50px) rotateZ(133deg);
			}
			80% {
				transform: rotateZ(-133deg) rotateY(85deg) translateX(50px) rotateZ(133deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-133deg) rotateY(85deg) translateX(150px) rotateZ(133deg);
			}
		}
		.c:nth-child(10) {
			animation: orbit10 6s infinite;
			animation-delay: 0.1s;
			background-color: #0088ff;
		}

		@keyframes orbit10 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-58deg) rotateY(116deg) translateX(50px) rotateZ(58deg);
			}
			80% {
				transform: rotateZ(-58deg) rotateY(116deg) translateX(50px) rotateZ(58deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-58deg) rotateY(116deg) translateX(150px) rotateZ(58deg);
			}
		}
		.c:nth-child(11) {
			animation: orbit11 6s infinite;
			animation-delay: 0.11s;
			background-color: #0085ff;
		}

		@keyframes orbit11 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-248deg) rotateY(5deg) translateX(50px) rotateZ(248deg);
			}
			80% {
				transform: rotateZ(-248deg) rotateY(5deg) translateX(50px) rotateZ(248deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-248deg) rotateY(5deg) translateX(150px) rotateZ(248deg);
			}
		}
		.c:nth-child(12) {
			animation: orbit12 6s infinite;
			animation-delay: 0.12s;
			background-color: #0081ff;
		}

		@keyframes orbit12 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-217deg) rotateY(28deg) translateX(50px) rotateZ(217deg);
			}
			80% {
				transform: rotateZ(-217deg) rotateY(28deg) translateX(50px) rotateZ(217deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-217deg) rotateY(28deg) translateX(150px) rotateZ(217deg);
			}
		}
		.c:nth-child(13) {
			animation: orbit13 6s infinite;
			animation-delay: 0.13s;
			background-color: #007eff;
		}

		@keyframes orbit13 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-261deg) rotateY(171deg) translateX(50px) rotateZ(261deg);
			}
			80% {
				transform: rotateZ(-261deg) rotateY(171deg) translateX(50px) rotateZ(261deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-261deg) rotateY(171deg) translateX(150px) rotateZ(261deg);
			}
		}
		.c:nth-child(14) {
			animation: orbit14 6s infinite;
			animation-delay: 0.14s;
			background-color: #007aff;
		}

		@keyframes orbit14 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-259deg) rotateY(268deg) translateX(50px) rotateZ(259deg);
			}
			80% {
				transform: rotateZ(-259deg) rotateY(268deg) translateX(50px) rotateZ(259deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-259deg) rotateY(268deg) translateX(150px) rotateZ(259deg);
			}
		}
		.c:nth-child(15) {
			animation: orbit15 6s infinite;
			animation-delay: 0.15s;
			background-color: #0077ff;
		}

		@keyframes orbit15 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-114deg) rotateY(144deg) translateX(50px) rotateZ(114deg);
			}
			80% {
				transform: rotateZ(-114deg) rotateY(144deg) translateX(50px) rotateZ(114deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-114deg) rotateY(144deg) translateX(150px) rotateZ(114deg);
			}
		}
		.c:nth-child(16) {
			animation: orbit16 6s infinite;
			animation-delay: 0.16s;
			background-color: #0074ff;
		}

		@keyframes orbit16 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-156deg) rotateY(123deg) translateX(50px) rotateZ(156deg);
			}
			80% {
				transform: rotateZ(-156deg) rotateY(123deg) translateX(50px) rotateZ(156deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-156deg) rotateY(123deg) translateX(150px) rotateZ(156deg);
			}
		}
		.c:nth-child(17) {
			animation: orbit17 6s infinite;
			animation-delay: 0.17s;
			background-color: #0070ff;
		}

		@keyframes orbit17 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-204deg) rotateY(349deg) translateX(50px) rotateZ(204deg);
			}
			80% {
				transform: rotateZ(-204deg) rotateY(349deg) translateX(50px) rotateZ(204deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-204deg) rotateY(349deg) translateX(150px) rotateZ(204deg);
			}
		}
		.c:nth-child(18) {
			animation: orbit18 6s infinite;
			animation-delay: 0.18s;
			background-color: #006dff;
		}

		@keyframes orbit18 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-250deg) rotateY(277deg) translateX(50px) rotateZ(250deg);
			}
			80% {
				transform: rotateZ(-250deg) rotateY(277deg) translateX(50px) rotateZ(250deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-250deg) rotateY(277deg) translateX(150px) rotateZ(250deg);
			}
		}
		.c:nth-child(19) {
			animation: orbit19 6s infinite;
			animation-delay: 0.19s;
			background-color: #0069ff;
		}

		@keyframes orbit19 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-114deg) rotateY(337deg) translateX(50px) rotateZ(114deg);
			}
			80% {
				transform: rotateZ(-114deg) rotateY(337deg) translateX(50px) rotateZ(114deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-114deg) rotateY(337deg) translateX(150px) rotateZ(114deg);
			}
		}
		.c:nth-child(20) {
			animation: orbit20 6s infinite;
			animation-delay: 0.2s;
			background-color: #0066ff;
		}

		@keyframes orbit20 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-165deg) rotateY(220deg) translateX(50px) rotateZ(165deg);
			}
			80% {
				transform: rotateZ(-165deg) rotateY(220deg) translateX(50px) rotateZ(165deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-165deg) rotateY(220deg) translateX(150px) rotateZ(165deg);
			}
		}
		.c:nth-child(21) {
			animation: orbit21 6s infinite;
			animation-delay: 0.21s;
			background-color: #0063ff;
		}

		@keyframes orbit21 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-194deg) rotateY(110deg) translateX(50px) rotateZ(194deg);
			}
			80% {
				transform: rotateZ(-194deg) rotateY(110deg) translateX(50px) rotateZ(194deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-194deg) rotateY(110deg) translateX(150px) rotateZ(194deg);
			}
		}
		.c:nth-child(22) {
			animation: orbit22 6s infinite;
			animation-delay: 0.22s;
			background-color: #005fff;
		}

		@keyframes orbit22 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-163deg) rotateY(161deg) translateX(50px) rotateZ(163deg);
			}
			80% {
				transform: rotateZ(-163deg) rotateY(161deg) translateX(50px) rotateZ(163deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-163deg) rotateY(161deg) translateX(150px) rotateZ(163deg);
			}
		}
		.c:nth-child(23) {
			animation: orbit23 6s infinite;
			animation-delay: 0.23s;
			background-color: #005cff;
		}

		@keyframes orbit23 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-127deg) rotateY(201deg) translateX(50px) rotateZ(127deg);
			}
			80% {
				transform: rotateZ(-127deg) rotateY(201deg) translateX(50px) rotateZ(127deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-127deg) rotateY(201deg) translateX(150px) rotateZ(127deg);
			}
		}
		.c:nth-child(24) {
			animation: orbit24 6s infinite;
			animation-delay: 0.24s;
			background-color: #0058ff;
		}

		@keyframes orbit24 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-309deg) rotateY(358deg) translateX(50px) rotateZ(309deg);
			}
			80% {
				transform: rotateZ(-309deg) rotateY(358deg) translateX(50px) rotateZ(309deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-309deg) rotateY(358deg) translateX(150px) rotateZ(309deg);
			}
		}
		.c:nth-child(25) {
			animation: orbit25 6s infinite;
			animation-delay: 0.25s;
			background-color: #0055ff;
		}

		@keyframes orbit25 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-290deg) rotateY(80deg) translateX(50px) rotateZ(290deg);
			}
			80% {
				transform: rotateZ(-290deg) rotateY(80deg) translateX(50px) rotateZ(290deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-290deg) rotateY(80deg) translateX(150px) rotateZ(290deg);
			}
		}
		.c:nth-child(26) {
			animation: orbit26 6s infinite;
			animation-delay: 0.26s;
			background-color: #0052ff;
		}

		@keyframes orbit26 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-77deg) rotateY(21deg) translateX(50px) rotateZ(77deg);
			}
			80% {
				transform: rotateZ(-77deg) rotateY(21deg) translateX(50px) rotateZ(77deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-77deg) rotateY(21deg) translateX(150px) rotateZ(77deg);
			}
		}
		.c:nth-child(27) {
			animation: orbit27 6s infinite;
			animation-delay: 0.27s;
			background-color: #004eff;
		}

		@keyframes orbit27 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-218deg) rotateY(260deg) translateX(50px) rotateZ(218deg);
			}
			80% {
				transform: rotateZ(-218deg) rotateY(260deg) translateX(50px) rotateZ(218deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-218deg) rotateY(260deg) translateX(150px) rotateZ(218deg);
			}
		}
		.c:nth-child(28) {
			animation: orbit28 6s infinite;
			animation-delay: 0.28s;
			background-color: #004bff;
		}

		@keyframes orbit28 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-28deg) rotateY(24deg) translateX(50px) rotateZ(28deg);
			}
			80% {
				transform: rotateZ(-28deg) rotateY(24deg) translateX(50px) rotateZ(28deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-28deg) rotateY(24deg) translateX(150px) rotateZ(28deg);
			}
		}
		.c:nth-child(29) {
			animation: orbit29 6s infinite;
			animation-delay: 0.29s;
			background-color: #0047ff;
		}

		@keyframes orbit29 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-170deg) rotateY(213deg) translateX(50px) rotateZ(170deg);
			}
			80% {
				transform: rotateZ(-170deg) rotateY(213deg) translateX(50px) rotateZ(170deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-170deg) rotateY(213deg) translateX(150px) rotateZ(170deg);
			}
		}
		.c:nth-child(30) {
			animation: orbit30 6s infinite;
			animation-delay: 0.3s;
			background-color: #0044ff;
		}

		@keyframes orbit30 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-9deg) rotateY(46deg) translateX(50px) rotateZ(9deg);
			}
			80% {
				transform: rotateZ(-9deg) rotateY(46deg) translateX(50px) rotateZ(9deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-9deg) rotateY(46deg) translateX(150px) rotateZ(9deg);
			}
		}
		.c:nth-child(31) {
			animation: orbit31 6s infinite;
			animation-delay: 0.31s;
			background-color: #0041ff;
		}

		@keyframes orbit31 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-333deg) rotateY(166deg) translateX(50px) rotateZ(333deg);
			}
			80% {
				transform: rotateZ(-333deg) rotateY(166deg) translateX(50px) rotateZ(333deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-333deg) rotateY(166deg) translateX(150px) rotateZ(333deg);
			}
		}
		.c:nth-child(32) {
			animation: orbit32 6s infinite;
			animation-delay: 0.32s;
			background-color: #003dff;
		}

		@keyframes orbit32 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-190deg) rotateY(187deg) translateX(50px) rotateZ(190deg);
			}
			80% {
				transform: rotateZ(-190deg) rotateY(187deg) translateX(50px) rotateZ(190deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-190deg) rotateY(187deg) translateX(150px) rotateZ(190deg);
			}
		}
		.c:nth-child(33) {
			animation: orbit33 6s infinite;
			animation-delay: 0.33s;
			background-color: #003aff;
		}

		@keyframes orbit33 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-319deg) rotateY(180deg) translateX(50px) rotateZ(319deg);
			}
			80% {
				transform: rotateZ(-319deg) rotateY(180deg) translateX(50px) rotateZ(319deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-319deg) rotateY(180deg) translateX(150px) rotateZ(319deg);
			}
		}
		.c:nth-child(34) {
			animation: orbit34 6s infinite;
			animation-delay: 0.34s;
			background-color: #0036ff;
		}

		@keyframes orbit34 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-352deg) rotateY(184deg) translateX(50px) rotateZ(352deg);
			}
			80% {
				transform: rotateZ(-352deg) rotateY(184deg) translateX(50px) rotateZ(352deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-352deg) rotateY(184deg) translateX(150px) rotateZ(352deg);
			}
		}
		.c:nth-child(35) {
			animation: orbit35 6s infinite;
			animation-delay: 0.35s;
			background-color: #0033ff;
		}

		@keyframes orbit35 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-282deg) rotateY(186deg) translateX(50px) rotateZ(282deg);
			}
			80% {
				transform: rotateZ(-282deg) rotateY(186deg) translateX(50px) rotateZ(282deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-282deg) rotateY(186deg) translateX(150px) rotateZ(282deg);
			}
		}
		.c:nth-child(36) {
			animation: orbit36 6s infinite;
			animation-delay: 0.36s;
			background-color: #0030ff;
		}

		@keyframes orbit36 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-23deg) rotateY(305deg) translateX(50px) rotateZ(23deg);
			}
			80% {
				transform: rotateZ(-23deg) rotateY(305deg) translateX(50px) rotateZ(23deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-23deg) rotateY(305deg) translateX(150px) rotateZ(23deg);
			}
		}
		.c:nth-child(37) {
			animation: orbit37 6s infinite;
			animation-delay: 0.37s;
			background-color: #002cff;
		}

		@keyframes orbit37 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-60deg) rotateY(204deg) translateX(50px) rotateZ(60deg);
			}
			80% {
				transform: rotateZ(-60deg) rotateY(204deg) translateX(50px) rotateZ(60deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-60deg) rotateY(204deg) translateX(150px) rotateZ(60deg);
			}
		}
		.c:nth-child(38) {
			animation: orbit38 6s infinite;
			animation-delay: 0.38s;
			background-color: #0029ff;
		}

		@keyframes orbit38 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-11deg) rotateY(167deg) translateX(50px) rotateZ(11deg);
			}
			80% {
				transform: rotateZ(-11deg) rotateY(167deg) translateX(50px) rotateZ(11deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-11deg) rotateY(167deg) translateX(150px) rotateZ(11deg);
			}
		}
		.c:nth-child(39) {
			animation: orbit39 6s infinite;
			animation-delay: 0.39s;
			background-color: #0025ff;
		}

		@keyframes orbit39 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-248deg) rotateY(117deg) translateX(50px) rotateZ(248deg);
			}
			80% {
				transform: rotateZ(-248deg) rotateY(117deg) translateX(50px) rotateZ(248deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-248deg) rotateY(117deg) translateX(150px) rotateZ(248deg);
			}
		}
		.c:nth-child(40) {
			animation: orbit40 6s infinite;
			animation-delay: 0.4s;
			background-color: #0022ff;
		}

		@keyframes orbit40 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-11deg) rotateY(234deg) translateX(50px) rotateZ(11deg);
			}
			80% {
				transform: rotateZ(-11deg) rotateY(234deg) translateX(50px) rotateZ(11deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-11deg) rotateY(234deg) translateX(150px) rotateZ(11deg);
			}
		}
		.c:nth-child(41) {
			animation: orbit41 6s infinite;
			animation-delay: 0.41s;
			background-color: #001fff;
		}

		@keyframes orbit41 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-98deg) rotateY(137deg) translateX(50px) rotateZ(98deg);
			}
			80% {
				transform: rotateZ(-98deg) rotateY(137deg) translateX(50px) rotateZ(98deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-98deg) rotateY(137deg) translateX(150px) rotateZ(98deg);
			}
		}
		.c:nth-child(42) {
			animation: orbit42 6s infinite;
			animation-delay: 0.42s;
			background-color: #001bff;
		}

		@keyframes orbit42 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-246deg) rotateY(128deg) translateX(50px) rotateZ(246deg);
			}
			80% {
				transform: rotateZ(-246deg) rotateY(128deg) translateX(50px) rotateZ(246deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-246deg) rotateY(128deg) translateX(150px) rotateZ(246deg);
			}
		}
		.c:nth-child(43) {
			animation: orbit43 6s infinite;
			animation-delay: 0.43s;
			background-color: #0018ff;
		}

		@keyframes orbit43 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-98deg) rotateY(87deg) translateX(50px) rotateZ(98deg);
			}
			80% {
				transform: rotateZ(-98deg) rotateY(87deg) translateX(50px) rotateZ(98deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-98deg) rotateY(87deg) translateX(150px) rotateZ(98deg);
			}
		}
		.c:nth-child(44) {
			animation: orbit44 6s infinite;
			animation-delay: 0.44s;
			background-color: #0014ff;
		}

		@keyframes orbit44 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-298deg) rotateY(173deg) translateX(50px) rotateZ(298deg);
			}
			80% {
				transform: rotateZ(-298deg) rotateY(173deg) translateX(50px) rotateZ(298deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-298deg) rotateY(173deg) translateX(150px) rotateZ(298deg);
			}
		}
		.c:nth-child(45) {
			animation: orbit45 6s infinite;
			animation-delay: 0.45s;
			background-color: #0011ff;
		}

		@keyframes orbit45 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-277deg) rotateY(47deg) translateX(50px) rotateZ(277deg);
			}
			80% {
				transform: rotateZ(-277deg) rotateY(47deg) translateX(50px) rotateZ(277deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-277deg) rotateY(47deg) translateX(150px) rotateZ(277deg);
			}
		}
		.c:nth-child(46) {
			animation: orbit46 6s infinite;
			animation-delay: 0.46s;
			background-color: #000eff;
		}

		@keyframes orbit46 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-48deg) rotateY(195deg) translateX(50px) rotateZ(48deg);
			}
			80% {
				transform: rotateZ(-48deg) rotateY(195deg) translateX(50px) rotateZ(48deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-48deg) rotateY(195deg) translateX(150px) rotateZ(48deg);
			}
		}
		.c:nth-child(47) {
			animation: orbit47 6s infinite;
			animation-delay: 0.47s;
			background-color: #000aff;
		}

		@keyframes orbit47 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-355deg) rotateY(160deg) translateX(50px) rotateZ(355deg);
			}
			80% {
				transform: rotateZ(-355deg) rotateY(160deg) translateX(50px) rotateZ(355deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-355deg) rotateY(160deg) translateX(150px) rotateZ(355deg);
			}
		}
		.c:nth-child(48) {
			animation: orbit48 6s infinite;
			animation-delay: 0.48s;
			background-color: #0007ff;
		}

		@keyframes orbit48 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-59deg) rotateY(29deg) translateX(50px) rotateZ(59deg);
			}
			80% {
				transform: rotateZ(-59deg) rotateY(29deg) translateX(50px) rotateZ(59deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-59deg) rotateY(29deg) translateX(150px) rotateZ(59deg);
			}
		}
		.c:nth-child(49) {
			animation: orbit49 6s infinite;
			animation-delay: 0.49s;
			background-color: #0003ff;
		}

		@keyframes orbit49 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-260deg) rotateY(28deg) translateX(50px) rotateZ(260deg);
			}
			80% {
				transform: rotateZ(-260deg) rotateY(28deg) translateX(50px) rotateZ(260deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-260deg) rotateY(28deg) translateX(150px) rotateZ(260deg);
			}
		}
		.c:nth-child(50) {
			animation: orbit50 6s infinite;
			animation-delay: 0.5s;
			background-color: red;
		}

		@keyframes orbit50 {
			20% {
				opacity: 1;
			}
			30% {
				transform: rotateZ(-165deg) rotateY(209deg) translateX(50px) rotateZ(165deg);
			}
			80% {
				transform: rotateZ(-165deg) rotateY(209deg) translateX(50px) rotateZ(165deg);
				opacity: 1;
			}
			100% {
				transform: rotateZ(-165deg) rotateY(209deg) translateX(150px) rotateZ(165deg);
			}
		}
	</style>
{/if}
